import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip, useMap, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { useNavigate } from 'react-router-dom';
import { formatSchoolName } from '../utils/nameFormatter';
import { 
  Container, Grid, Card, CardContent, Typography, Box, FormControl, 
  InputLabel, Select, MenuItem, Button
} from '@mui/material';
import { SchoolOutlined, PeopleOutlined, RefreshOutlined } from '@mui/icons-material';

const API_URL = process.env.REACT_APP_BACKEND_URL;

const customIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

function SetViewOnDataLoad({ schools }) {
  const map = useMap();
  useEffect(() => {
    if (schools.length > 0) {
      const bounds = L.latLngBounds(schools.map(school => [school.latitude, school.longitude]));
      map.fitBounds(bounds, { padding: [100, 100] });
    }
  }, [schools, map]);
  return null;
}

function ResetZoom({ resetZoom }) {
  const map = useMap();
  useEffect(() => {
    if (resetZoom) {
      map.setView([39.8283, -98.5795], 4);
    }
  }, [resetZoom, map]);
  return null;
}

function MapEventHandler({ onViewportChanged }) {
  const map = useMapEvents({
    moveend: () => onViewportChanged(map),
    zoomend: () => onViewportChanged(map),
  });
  return null;
}

// New function to parse enrollment numbers
const parseEnrollment = (enrollmentValue) => {
  if (typeof enrollmentValue === 'number') return enrollmentValue;
  if (typeof enrollmentValue === 'string') {
    return parseInt(enrollmentValue.replace(/[,.]/g, ''), 10) || 0;
  }
  return 0;
};

function Map() {
  const [schools, setSchools] = useState([]);
  const [mapCenter] = useState([39.8283, -98.5795]);
  const [error, setError] = useState(null);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [resetZoom, setResetZoom] = useState(false);
  const [visibleSchools, setVisibleSchools] = useState([]);
  const navigate = useNavigate();
  const markerClusterRef = useRef();

  useEffect(() => {
    const fetchSchools = async () => {
      const token = localStorage.getItem('access_token');
      if (!token) {
        setError('No access token found. Please log in again.');
        navigate('/login');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/schools`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          if (response.status === 401) {
            localStorage.removeItem('access_token');
            navigate('/login');
            return;
          }
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('API response:', data); // Debugging log
        
        // Ensure enrollment is stored as a number
        const processedData = data.map(school => ({
          ...school,
          enrollment: parseEnrollment(school.enrollment)
        }));
        
        setSchools(processedData);
        setVisibleSchools(processedData);
      } catch (error) {
        console.error('Error fetching school data:', error);
        setError('Failed to load school data. Please try again later.');
      }
    };

    fetchSchools();
  }, [navigate]);

  const filteredSchools = useMemo(() => {
    return schools.filter(school => 
      (!selectedState || school.state === selectedState) &&
      (!selectedCity || school.city === selectedCity)
    );
  }, [schools, selectedState, selectedCity]);

  const groupedSchools = useMemo(() => {
    const grouped = {};
    filteredSchools.forEach(school => {
      const key = `${school.latitude},${school.longitude}`;
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(school);
    });
    return grouped;
  }, [filteredSchools]);

  const states = useMemo(() => {
    return [...new Set(schools.map(school => school.state))].sort();
  }, [schools]);

  const cities = useMemo(() => {
    return [...new Set(schools.filter(school => !selectedState || school.state === selectedState)
      .map(school => school.city))].sort();
  }, [schools, selectedState]);

  const totalStudents = useMemo(() => {
    const total = visibleSchools.reduce((sum, school) => {
      const enrollment = parseEnrollment(school.enrollment);
      console.log(`School: ${school.name}, Enrollment: ${enrollment}, Running Sum: ${sum + enrollment}`); // Debugging log
      return sum + enrollment;
    }, 0);
    console.log('Final total:', total); // Debugging log
    return total;
  }, [visibleSchools]);

  const handleResetFilters = () => {
    setSelectedState('');
    setSelectedCity('');
    setVisibleSchools(schools);
    setResetZoom(true);
    setTimeout(() => setResetZoom(false), 100);
  };

  const handleViewportChanged = useCallback((map) => {
    if (markerClusterRef.current && markerClusterRef.current.getLayers) {
      const bounds = map.getBounds();
      const visible = [];
      markerClusterRef.current.getLayers().forEach(layer => {
        if (layer instanceof L.Marker) {
          if (bounds.contains(layer.getLatLng())) {
            visible.push(...layer.options.schools);
          }
        }
      });
      setVisibleSchools(visible);
    }
  }, []);

  useEffect(() => {
    setVisibleSchools(filteredSchools);
  }, [filteredSchools]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 1, fontWeight: 'bold' }}>
        Network Map
      </Typography>
      <Typography variant="subtitle1" gutterBottom sx={{ mb: 4 }}>
        Explore schools in your network
      </Typography>
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%' }}>
                <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <SchoolOutlined sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
                    <Typography variant="h6">Total Schools</Typography>
                  </Box>
                  <Typography variant="h4">{visibleSchools.length}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%' }}>
                <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <PeopleOutlined sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
                    <Typography variant="h6">Total Students</Typography>
                  </Box>
                  <Typography variant="h4">{totalStudents.toLocaleString()}</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Select State</InputLabel>
              <Select
                value={selectedState}
                onChange={(e) => {
                  setSelectedState(e.target.value);
                  setSelectedCity('');
                }}
              >
                <MenuItem value="">All States</MenuItem>
                {states.map(state => (
                  <MenuItem key={state} value={state}>{state}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Select City</InputLabel>
              <Select
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
              >
                <MenuItem value="">All Cities</MenuItem>
                {cities.map(city => (
                  <MenuItem key={city} value={city}>{city}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                startIcon={<RefreshOutlined />}
                onClick={handleResetFilters}
              >
                Reset Filters
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <MapContainer center={mapCenter} zoom={4} style={{ height: "600px", width: "100%" }}>
        <TileLayer
          url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
        />
        <MarkerClusterGroup
          ref={markerClusterRef}
          chunkedLoading
          spiderfyOnMaxZoom={false}
          zoomToBoundsOnClick={true}
          maxClusterRadius={60}
          disableClusteringAtZoom={15}
          minimumClusterSize={4}
        >
          {Object.entries(groupedSchools).map(([key, schoolsAtLocation], index) => (
            <Marker 
              key={index} 
              position={schoolsAtLocation[0].latitude && schoolsAtLocation[0].longitude ? [schoolsAtLocation[0].latitude, schoolsAtLocation[0].longitude] : [0, 0]}
              icon={customIcon}
              schools={schoolsAtLocation}
            >
              <Tooltip direction="top" offset={[0, -20]} opacity={1}>
                {schoolsAtLocation.map((school, idx) => (
                  <div key={idx}>
                    <strong>{formatSchoolName(school.name)}</strong><br />
                    Enrollment: {school.enrollment !== null ? school.enrollment.toLocaleString() : 'Not available'}<br />
                    Grade Range: {school.grade_range || 'Not available'}
                    {idx < schoolsAtLocation.length - 1 && <hr />}
                  </div>
                ))}
              </Tooltip>
            </Marker>
          ))}
        </MarkerClusterGroup>
        <SetViewOnDataLoad schools={filteredSchools} />
        <ResetZoom resetZoom={resetZoom} />
        <MapEventHandler onViewportChanged={handleViewportChanged} />
      </MapContainer>
    </Container>
  );
}

export default Map;