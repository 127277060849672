import React, { useState, useEffect, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Box, Typography, Chip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler, annotationPlugin);

function SchoolEnrollmentProjectionChart({ school, enrollmentData, selectedGrade }) {
  const [chartData, setChartData] = useState(null);
  const [outerMinMaxValues, setOuterMinMaxValues] = useState({ min: null, max: null });
  const dataSource = enrollmentData.based_on_user_data ? "Historical and user-inputted data" : "Historical data only";

  const processEnrollmentData = useCallback(() => {
    if (!enrollmentData) return null;

    let years = new Set();
    let actualData = [];
    let projectedData = {
      min: [], median: [], max: []
    };

    // Process historical enrollment data
    Object.entries(enrollmentData.actual_enrollment || {}).forEach(([year, yearData]) => {
      years.add(year);
      const enrollment = selectedGrade === 'total' 
        ? Object.values(yearData).reduce((sum, val) => sum + val, 0)
        : yearData[selectedGrade] || 0;
      actualData.push({ x: year, y: enrollment });
    });

    // Process projection data
    ['min', 'median', 'max'].forEach(projType => {
      Object.entries(enrollmentData.projections?.[projType] || {}).forEach(([year, yearData]) => {
        years.add(year);
        const enrollment = selectedGrade === 'total'
          ? Object.values(yearData).reduce((sum, val) => sum + val, 0)
          : yearData[selectedGrade] || 0;
        projectedData[projType].push({ x: year, y: enrollment });
      });
    });

    const datasets = [
      {
        label: 'Actual',
        data: actualData,
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        fill: false,
        tension: 0.1
      },
      {
        label: 'High Projection',
        data: projectedData.max,
        borderColor: 'rgba(0, 0, 255, 1)', // Blue
        backgroundColor: 'rgba(0, 0, 255, 0.5)',
        fill: false,
        tension: 0.1
      },
      {
        label: 'Medium Projection',
        data: projectedData.median,
        borderColor: 'rgba(0, 255, 0, 1)', // Green
        backgroundColor: 'rgba(0, 255, 0, 0.5)',
        fill: false,
        tension: 0.1
      },
      {
        label: 'Low Projection',
        data: projectedData.min,
        borderColor: 'rgba(255, 0, 0, 1)', // Red
        backgroundColor: 'rgba(255, 0, 0, 0.5)',
        fill: false,
        tension: 0.1
      },
      {
        label: 'Projection Range',
        data: projectedData.max,
        fill: '-1',
        backgroundColor: 'rgba(200, 200, 200, 0.3)', // Grey shading
        borderColor: 'transparent',
        pointRadius: 0,
        tension: 0.1
      }
    ];

    const allValues = [...actualData, ...projectedData.min, ...projectedData.max].map(item => item.y);
    const outerMin = Math.min(...allValues);
    const outerMax = Math.max(...allValues);

    setOuterMinMaxValues({ min: outerMin, max: outerMax });

    return { labels: Array.from(years).sort(), datasets };
  }, [enrollmentData, selectedGrade]);

  useEffect(() => {
    const chartData = processEnrollmentData();
    if (chartData) {
      setChartData(chartData);
    }
  }, [processEnrollmentData]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { 
        display: true,
        position: 'top',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      annotation: {
        annotations: {
          minLine: {
            type: 'line',
            yMin: outerMinMaxValues.min,
            yMax: outerMinMaxValues.min,
            borderColor: 'rgba(100, 100, 100, 1)', // Darker grey
            borderWidth: 2,
            borderDash: [5, 5],
            label: {
              display: true,
              content: '5-year Min',
              position: 'start',
              backgroundColor: 'rgba(100, 100, 100, 0.8)',
              color: 'white',
              font: { size: 12, weight: 'bold' }
            }
          },
          maxLine: {
            type: 'line',
            yMin: outerMinMaxValues.max,
            yMax: outerMinMaxValues.max,
            borderColor: 'rgba(100, 100, 100, 1)', // Darker grey
            borderWidth: 2,
            borderDash: [5, 5],
            label: {
              display: true,
              content: '5-year Max',
              position: 'end',
              backgroundColor: 'rgba(100, 100, 100, 0.8)',
              color: 'white',
              font: { size: 12, weight: 'bold' }
            }
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: { 
          display: true, 
          text: 'Enrollment',
        },
      },
      x: {
        title: { 
          display: true, 
          text: 'Year',
        },
      }
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Chip
          icon={<InfoIcon />}
          label={`Data source: ${dataSource}`}
          color="primary"
          variant="outlined"
        />
      </Box>
      {chartData && chartData.datasets.some(dataset => dataset.data.length > 0) ? (
        <div style={{ width: '100%', height: 400 }}>
          <Line data={chartData} options={options} />
        </div>
      ) : (
        <Typography>No enrollment data available for the selected grade.</Typography>
      )}
    </Box>
  );
}

export default SchoolEnrollmentProjectionChart;