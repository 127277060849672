import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Container,
  Paper,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  useTheme,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import { useNavigate } from 'react-router-dom';
import { formatSchoolName } from '../utils/nameFormatter';

const API_URL = process.env.REACT_APP_BACKEND_URL;

const GRADE_ORDER = [
  'PreKindergarten', 'Kindergarten', 
  'Grade 1', 'Grade 2', 'Grade 3', 'Grade 4', 'Grade 5', 'Grade 6', 
  'Grade 7', 'Grade 8', 'Grade 9', 'Grade 10', 'Grade 11', 'Grade 12'
];

const EnrollmentEntry = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [enrollmentOption, setEnrollmentOption] = useState('');
  const [schoolData, setSchoolData] = useState({
    schoolId: '',
    schoolName: '',
    '2023-2024': { grades: {} },
    '2024-2025': { grades: {} }
  });
  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [additionalGrades, setAdditionalGrades] = useState({
    '2023-2024': [],
    '2024-2025': []
  });

  const fetchSchools = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        setError('No access token found. Please log in again.');
        navigate('/login');
        return;
      }

      const response = await fetch(`${API_URL}/schools`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem('access_token');
          navigate('/login');
          return;
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched schools data:', data);

      const csgfSchools = data.filter(school => school.funder === "CSGF");
      const uniqueSchools = Array.from(new Map(csgfSchools.map(school => [school.ncessch, school])).values())
      .sort((a, b) => formatSchoolName(a.name).localeCompare(formatSchoolName(b.name))); // Update sorting
      console.log('Unique CSGF schools:', uniqueSchools);

      setSchools(uniqueSchools);
    } catch (error) {
      console.error('Error fetching school data:', error);
      setError('Failed to load school data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    fetchSchools();
  }, [fetchSchools]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'schoolId') {
      const selectedSchool = schools.find(school => school.ncessch === value);
      if (selectedSchool) {
        const initialGrades = {};
        selectedSchool.grades.forEach(grade => {
          initialGrades[grade] = '';
        });
        setSchoolData(prevData => ({
          ...prevData,
          schoolId: value,
          schoolName: formatSchoolName(selectedSchool.name),
          '2023-2024': { grades: initialGrades },
          '2024-2025': { grades: initialGrades }
        }));
        setAdditionalGrades({
          '2023-2024': [],
          '2024-2025': []
        });
      }
    }
  };

  const handleGradeChange = (year, grade) => (event) => {
    const { value } = event.target;
    setSchoolData(prevData => ({
      ...prevData,
      [year]: {
        ...prevData[year],
        grades: {
          ...prevData[year].grades,
          [grade]: value
        }
      }
    }));

    validateGrade(year, grade, value);
  };

  const handleAdditionalGradeChange = (year, index, field) => (event) => {
    const { value } = event.target;
    setAdditionalGrades(prevGrades => ({
      ...prevGrades,
      [year]: prevGrades[year].map((grade, i) => 
        i === index ? { ...grade, [field]: value } : grade
      )
    }));

    if (field === 'enrollment') {
      validateGrade(year, `additional-${index}`, value);
    }
  };

  const validateGrade = (year, grade, value) => {
    if (value === '') {
      setValidationErrors(prevErrors => ({
        ...prevErrors,
        [`${year}-${grade}`]: 'Enrollment is required for all historical grades.'
      }));
    } else if (value !== 'no_longer_offered' && parseInt(value, 10) > 500) {
      setValidationErrors(prevErrors => ({
        ...prevErrors,
        [`${year}-${grade}`]: 'Enrollment exceeds 500 students. Please verify this number.'
      }));
    } else {
      setValidationErrors(prevErrors => {
        const newErrors = { ...prevErrors };
        delete newErrors[`${year}-${grade}`];
        return newErrors;
      });
    }
  };

  const handleAddGrade = (year) => {
    if (additionalGrades[year].length < 3) {
      setAdditionalGrades(prevGrades => ({
        ...prevGrades,
        [year]: [...prevGrades[year], { grade: '', enrollment: '' }]
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validation checks
    let incompleteGrades = [];
    Object.entries(schoolData).forEach(([year, data]) => {
      if (year === '2023-2024' || (year === '2024-2025' && enrollmentOption === 'both')) {
        incompleteGrades = [
          ...incompleteGrades,
          ...Object.entries(data.grades).filter(([_, value]) => value === '')
        ];
      }
    });

    if (incompleteGrades.length > 0) {
      setDialogMessage('Please enter enrollment data for all historical grades before submitting.');
      setOpenDialog(true);
      return;
    }

    if (Object.keys(validationErrors).length > 0) {
      setDialogMessage('Please correct the errors before submitting.');
      setOpenDialog(true);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error('No access token found. Please log in again.');
      }

      // Prepare the data for submission
      const prepareGradeData = (year) => {
        const grades = { ...schoolData[year].grades };
        additionalGrades[year].forEach(({ grade, enrollment }) => {
          if (grade && enrollment) {
            grades[grade] = enrollment;
          }
        });
        return Object.entries(grades).reduce((acc, [grade, enrollment]) => {
          if (enrollment !== '') {
            acc[grade] = enrollment === 'no_longer_offered' ? enrollment : parseInt(enrollment, 10);
          }
          return acc;
        }, {});
      };

      // Submit enrollment data for 2023-2024
      const submissionData2023 = {
        schoolId: schoolData.schoolId,
        schoolName: schoolData.schoolName,
        year: '2023-2024',
        grades: prepareGradeData('2023-2024')
      };

      console.log('Submitting data for 2023-2024:', JSON.stringify(submissionData2023, null, 2));

      const response2023 = await fetch(`${API_URL}/submit-enrollment-data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(submissionData2023)
      });

      const responseData2023 = await response2023.json();

      if (!response2023.ok) {
        console.error('Server error response for 2023-2024:', responseData2023);
        throw new Error(responseData2023.message || `HTTP error! status: ${response2023.status}`);
      }

      console.log('Server response for 2023-2024:', responseData2023);

      // If user selected to enter data for both years, submit 2024-2025 data
      if (enrollmentOption === 'both') {
        const submissionData2024 = {
          schoolId: schoolData.schoolId,
          schoolName: schoolData.schoolName,
          year: '2024-2025',
          grades: prepareGradeData('2024-2025')
        };

        console.log('Submitting data for 2024-2025:', JSON.stringify(submissionData2024, null, 2));

        const response2024 = await fetch(`${API_URL}/submit-enrollment-data`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(submissionData2024)
        });

        const responseData2024 = await response2024.json();

        if (!response2024.ok) {
          console.error('Server error response for 2024-2025:', responseData2024);
          throw new Error(responseData2024.message || `HTTP error! status: ${response2024.status}`);
        }

        console.log('Server response for 2024-2025:', responseData2024);
      }

      // Trigger projections update
      const projectionsData = {
        ncessch: schoolData.schoolId,
        user_data: {
          schoolName: schoolData.schoolName,
          year: '2023-2024',
          grades: prepareGradeData('2023-2024')
        }
      };

      if (enrollmentOption === 'both') {
        projectionsData.user_data.year = '2024-2025';
        projectionsData.user_data.grades = {
          ...projectionsData.user_data.grades,
          ...prepareGradeData('2024-2025')
        };
      }

      console.log('Sending projections update data:', JSON.stringify(projectionsData, null, 2));

      const projectionsResponse = await fetch(`${API_URL}/update-projections`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(projectionsData)
      });

      const projectionsResult = await projectionsResponse.json();

      if (!projectionsResponse.ok) {
        console.error('Projections update error:', projectionsResult);
        throw new Error(projectionsResult.message || `Projections update failed: ${projectionsResponse.status}`);
      }

      console.log('Projections updated:', projectionsResult);

      setDialogMessage('Enrollment data submitted and projections updated successfully!');
      setOpenDialog(true);

      // Reset form
      setSchoolData({
        schoolId: '',
        schoolName: '',
        '2023-2024': { grades: {} },
        '2024-2025': { grades: {} }
      });
      setAdditionalGrades({
        '2023-2024': [],
        '2024-2025': []
      });
      setActiveStep(0);
      setEnrollmentOption('');
    } catch (error) {
      console.error('Error submitting enrollment data or updating projections:', error);
      setDialogMessage(error.message || 'Failed to submit data or update projections. Please try again.');
      setOpenDialog(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const enrollmentOptions = [
    { value: 'no_longer_offered', label: 'No longer offered' },
    ...Array.from({ length: 500 }, (_, i) => ({ value: i + 1, label: i + 1 }))
  ];

  const renderGradeInputs = (year) => {
    const sortedGrades = Object.entries(schoolData[year].grades).sort((a, b) => {
      return GRADE_ORDER.indexOf(a[0]) - GRADE_ORDER.indexOf(b[0]);
    });

    return (
      <>
        {sortedGrades.map(([grade, value]) => (
          <Grid item xs={12} sm={6} key={`${year}-${grade}`}>
            <FormControl fullWidth error={!!validationErrors[`${year}-${grade}`]}>
              <InputLabel>{grade}</InputLabel>
              <Select
                value={value}
                onChange={handleGradeChange(year, grade)}
                label={grade}
                required
              >
                {enrollmentOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {validationErrors[`${year}-${grade}`] && (
                <Typography variant="caption" color="error">
                  {validationErrors[`${year}-${grade}`]}
                </Typography>
              )}
            </FormControl>
          </Grid>
        ))}
        {additionalGrades[year].map((grade, index) => (
          <Grid item xs={12} container spacing={2} key={`${year}-additional-${index}`}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>{`Additional Grade ${index + 1}`}</InputLabel>
                <Select
                  value={grade.grade}
                  onChange={handleAdditionalGradeChange(year, index, 'grade')}
                  label={`Additional Grade ${index + 1}`}
                >
                  {GRADE_ORDER.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth error={!!validationErrors[`${year}-additional-${index}`]}>
                <InputLabel>{`Enrollment ${index + 1}`}</InputLabel>
                <Select
                  value={grade.enrollment}
                  onChange={handleAdditionalGradeChange(year, index, 'enrollment')}
                  label={`Enrollment ${index + 1}`}
                >
                  {enrollmentOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {validationErrors[`${year}-additional-${index}`] && (
                  <Typography variant="caption" color="error">
                    {validationErrors[`${year}-additional-${index}`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
        ))}
        {additionalGrades[year].length < 3 && (
          <Grid item xs={12}>
            <Button onClick={() => handleAddGrade(year)} variant="outlined">
              Add Grade
            </Button>
          </Grid>
        )}
      </>
    );
  };

  const steps = ['Select School', 'Choose Years', 'Enter Enrollment Data'];

  return (
    <Container maxWidth="md">
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <SchoolIcon sx={{ fontSize: 40, mr: 2, color: theme.palette.primary.main }} />
        <Typography variant="h4" component="h1">
          School Enrollment
        </Typography>
      </Box>
      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Paper elevation={3} sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {activeStep === 0 && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="school-select-label">School</InputLabel>
                  <Select
                    labelId="school-select-label"
                    id="school-select"
                    value={schoolData.schoolId}
                    label="School"
                    name="schoolId"
                    onChange={handleInputChange}
                    required
                  >
                    {schools.map((school) => (
                      <MenuItem key={school.ncessch} value={school.ncessch}>
                        {formatSchoolName(school.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {activeStep === 1 && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="enrollment-option-label">Enrollment Option</InputLabel>
                  <Select
                    labelId="enrollment-option-label"
                    id="enrollment-option-select"
                    value={enrollmentOption}
                    label="Enrollment Option"
                    onChange={(e) => setEnrollmentOption(e.target.value)}
                    required
                  >
                    <MenuItem value="2023-2024">2023-2024 Only</MenuItem>
                    <MenuItem value="both">Both 2023-2024 and 2024-2025</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            {activeStep === 2 && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6">2023-2024 Enrollment</Typography>
                </Grid>
                {renderGradeInputs('2023-2024')}
                {enrollmentOption === 'both' && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6">2024-2025 Enrollment</Typography>
                    </Grid>
                    {renderGradeInputs('2024-2025')}
                  </>
                )}
              </>
            )}
            <Grid item xs={12}>
              {activeStep > 0 && (
                <Button onClick={() => setActiveStep(prevStep => prevStep - 1)} sx={{ mr: 1 }}>
                  Back
                </Button>
              )}
              {activeStep < steps.length - 1 ? (
                <Button 
                  onClick={() => setActiveStep(prevStep => prevStep + 1)} 
                  variant="contained" 
                  disabled={
                    (activeStep === 0 && !schoolData.schoolId) ||
                    (activeStep === 1 && !enrollmentOption)
                  }
                >
                  Next
                </Button>
              ) : (
                <Button 
                  type="submit" 
                  variant="contained" 
                  color="primary"
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : 'Submit Enrollment Data'}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Paper>
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
      {Object.keys(validationErrors).length > 0 && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          Please correct the errors before submitting.
        </Alert>
      )}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Enrollment Submission"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default EnrollmentEntry;