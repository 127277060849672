import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { 
  ThemeProvider, 
  createTheme, 
  CssBaseline, 
  Box,
  CircularProgress,
} from '@mui/material';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Home from './components/Home';
import Map from './components/Map';
import Login from './components/Login';
import ResetPassword from './components/ResetPassword';
import ProtectedRoute from './components/ProtectedRoute';
import AddressDataVisualization from './components/AddressDataVisualization';
import DataEntryPage from './components/DataEntryPage';
import DriveTimePage from './components/DriveTimePage';
import MarketShareDashboard from './components/MarketShareDashboard';
import DataReportsPage from './components/DataReportsPage';
import SchoolDashboard from './components/SchoolDashboard';
import { auth } from './firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4338ca',
      light: '#6366f1',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    grey: {
      200: '#f3f4f6', // Light grey for sidebar
    },
  },
});

const getUsernameFromEmail = (email) => {
  return email.split('@')[0];
};

function AppContent() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    signOut(auth).then(() => {
      setUser(null);
    }).catch((error) => {
      console.error('Logout error:', error);
    });
  };

  const handleSidebarToggle = (isOpen) => {
    setSidebarOpen(isOpen);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const isAuthPage = location.pathname === '/login' || location.pathname === '/reset-password';
  const drawerWidth = sidebarOpen ? 0 : 0;

  return (
    <Box sx={{ display: 'flex' }}>
      {!isAuthPage && <Sidebar onToggle={handleSidebarToggle} />}
      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1, 
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          marginLeft: { sm: `${drawerWidth}px` },
          display: 'flex', 
          flexDirection: 'column',
          minHeight: '100vh',
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        {!isAuthPage && <Navbar user={user} onLogout={handleLogout} getUsernameFromEmail={getUsernameFromEmail} />}
        <Box sx={{ flexGrow: 1, p: 3, mt: 8 }}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/map" element={<ProtectedRoute user={user}><Map /></ProtectedRoute>} />
            <Route path="/" element={<ProtectedRoute user={user}><Home user={user} /></ProtectedRoute>} />
            <Route path="/address-visualization" element={<ProtectedRoute user={user}><AddressDataVisualization /></ProtectedRoute>} />
            <Route path="/data-entry" element={<ProtectedRoute user={user}><DataEntryPage /></ProtectedRoute>} />
            <Route path="/school-dashboard" element={<ProtectedRoute user={user}><SchoolDashboard /></ProtectedRoute>} />
            <Route path="/market-share" element={<ProtectedRoute user={user}><MarketShareDashboard /></ProtectedRoute>} />
            <Route path="/data-reports" element={<ProtectedRoute user={user}><DataReportsPage /></ProtectedRoute>} />
            <Route path="/data-reports/drive-time" element={<ProtectedRoute user={user}><DriveTimePage /></ProtectedRoute>} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Box>
        {!isAuthPage && <Footer />}
      </Box>
    </Box>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppContent />
      </Router>
    </ThemeProvider>
  );
}

export default App;