import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Alert
} from '@mui/material';
import { CSVLink } from 'react-csv';
import { formatSchoolName } from '../utils/nameFormatter';

const API_URL = process.env.REACT_APP_BACKEND_URL;

function DriveTimePage() {
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [esriData, setEsriData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSchools();
  }, []);

  const fetchSchools = async () => {
    try {
      const response = await fetch(`${API_URL}/schools`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setSchools(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching school data:', error);
      setError('Failed to load school data. Please try again later.');
      setLoading(false);
    }
  };

  const fetchEsriData = async (schoolId) => {
    try {
      const response = await fetch(`${API_URL}/school/${schoolId}/esri-data`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setEsriData(data);
    } catch (error) {
      console.error('Error fetching ESRI data:', error);
      setError('Failed to load ESRI data. Please try again later.');
    }
  };

  const handleSchoolChange = (event) => {
    const schoolId = event.target.value;
    const school = schools.find(s => s.ncessch === schoolId);
    if (school) {
      setSelectedSchool({...school, name: formatSchoolName(school.name)});
      fetchEsriData(schoolId);
    }
  };

  const formatNumber = (num) => {
    return num !== undefined && num !== null ? num.toLocaleString() : 'N/A';
  };

  const formatPercent = (num) => {
    return num !== undefined && num !== null ? `${(num * 100).toFixed(1)}%` : 'N/A';
  };

  const prepareCSVData = () => {
    if (!esriData) return [];

    const headers = [
      'Metric',
      '5 min Drive Time',
      '10 min Drive Time',
      '15 min Drive Time'
    ];

    const rows = [
      ['Total Population 5-9 (2020)'],
      ['Total Population 5-9 (Current Year)'],
      ['Total Population 5-9 (Forecast Year)'],
      ['Total Population 10-14 (2020)'],
      ['Total Population 10-14 (Current Year)'],
      ['Total Population 10-14 (Forecast Year)'],
      ['Total Population 15-19 (2020)'],
      ['Total Population 15-19 (Current Year)'],
      ['Total Population 15-19 (Forecast Year)'],
      ['American Indian/Native American Population 5-9 (2020)'],
      ['American Indian/Native American Population 5-9 (Current Year)'],
      ['American Indian/Native American Population 5-9 (Forecast Year)'],
      ['American Indian/Native American Population 10-14 (2020)'],
      ['American Indian/Native American Population 10-14 (Current Year)'],
      ['American Indian/Native American Population 10-14 (Forecast Year)'],
      ['American Indian/Native American Population 15-19 (2020)'],
      ['American Indian/Native American Population 15-19 (Current Year)'],
      ['American Indian/Native American Population 15-19 (Forecast Year)'],
      ['Asian Population 5-9 (2020)'],
      ['Asian Population 5-9 (Current Year)'],
      ['Asian Population 5-9 (Forecast Year)'],
      ['Asian Population 10-14 (2020)'],
      ['Asian Population 10-14 (Current Year)'],
      ['Asian Population 10-14 (Forecast Year)'],
      ['Asian Population 15-19 (2020)'],
      ['Asian Population 15-19 (Current Year)'],
      ['Asian Population 15-19 (Forecast Year)'],
      ['Black/African American Population 5-9 (2020)'],
      ['Black/African American Population 5-9 (Current Year)'],
      ['Black/African American Population 5-9 (Forecast Year)'],
      ['Black/African American Population 10-14 (2020)'],
      ['Black/African American Population 10-14 (Current Year)'],
      ['Black/African American Population 10-14 (Forecast Year)'],
      ['Black/African American Population 15-19 (2020)'],
      ['Black/African American Population 15-19 (Current Year)'],
      ['Black/African American Population 15-19 (Forecast Year)'],
      ['Hispanic/Latinx Population 5-9 (2020)'],
      ['Hispanic/Latinx Population 5-9 (Current Year)'],
      ['Hispanic/Latinx Population 5-9 (Forecast Year)'],
      ['Hispanic/Latinx Population 10-14 (2020)'],
      ['Hispanic/Latinx Population 10-14 (Current Year)'],
      ['Hispanic/Latinx Population 10-14 (Forecast Year)'],
      ['Hispanic/Latinx Population 15-19 (2020)'],
      ['Hispanic/Latinx Population 15-19 (Current Year)'],
      ['Hispanic/Latinx Population 15-19 (Forecast Year)'],
      ['Multiple Races Population 5-9 (2020)'],
      ['Multiple Races Population 5-9 (Current Year)'],
      ['Multiple Races Population 5-9 (Forecast Year)'],
      ['Multiple Races Population 10-14 (2020)'],
      ['Multiple Races Population 10-14 (Current Year)'],
      ['Multiple Races Population 10-14 (Forecast Year)'],
      ['Multiple Races Population 15-19 (2020)'],
      ['Multiple Races Population 15-19 (Current Year)'],
      ['Multiple Races Population 15-19 (Forecast Year)'],
      ['Other Races Population 5-9 (2020)'],
      ['Other Races Population 5-9 (Current Year)'],
      ['Other Races Population 5-9 (Forecast Year)'],
      ['Other Races Population 10-14 (2020)'],
      ['Other Races Population 10-14 (Current Year)'],
      ['Other Races Population 10-14 (Forecast Year)'],
      ['Other Races Population 15-19 (2020)'],
      ['Other Races Population 15-19 (Current Year)'],
      ['Other Races Population 15-19 (Forecast Year)'],
      ['Pacific Islander/Native Hawaiian Population 5-9 (2020)'],
      ['Pacific Islander/Native Hawaiian Population 5-9 (Current Year)'],
      ['Pacific Islander/Native Hawaiian Population 5-9 (Forecast Year)'],
      ['Pacific Islander/Native Hawaiian Population 10-14 (2020)'],
      ['Pacific Islander/Native Hawaiian Population 10-14 (Current Year)'],
      ['Pacific Islander/Native Hawaiian Population 10-14 (Forecast Year)'],
      ['Pacific Islander/Native Hawaiian Population 15-19 (2020)'],
      ['Pacific Islander/Native Hawaiian Population 15-19 (Current Year)'],
      ['Pacific Islander/Native Hawaiian Population 15-19 (Forecast Year)'],
      ['White Population 5-9 (2020)'],
      ['White Population 5-9 (Current Year)'],
      ['White Population 5-9 (Forecast Year)'],
      ['White Population 10-14 (2020)'],
      ['White Population 10-14 (Current Year)'],
      ['White Population 10-14 (Forecast Year)'],
      ['White Population 15-19 (2020)'],
      ['White Population 15-19 (Current Year)'],
      ['White Population 15-19 (Forecast Year)'],
      ['Households Under $50k (Current Year)'],
      ['Households Under $50k (Future Year)'],
    ];

    [5, 10, 15].forEach((time) => {
      const data = esriData.drive_times[time];
      rows[0].push(formatNumber(data.total_2020_population_5_9));
      rows[1].push(formatNumber(data.total_current_population_5_9));
      rows[2].push(formatNumber(data.total_future_population_5_9));
      rows[3].push(formatNumber(data.total_2020_population_10_14));
      rows[4].push(formatNumber(data.total_current_population_10_14));
      rows[5].push(formatNumber(data.total_future_population_10_14));
      rows[6].push(formatNumber(data.total_2020_population_15_19));
      rows[7].push(formatNumber(data.total_current_population_15_19));
      rows[8].push(formatNumber(data.total_future_population_15_19));
      rows[9].push(formatPercent(data.american_indian_2020_5_9_percent));
      rows[10].push(formatPercent(data.american_indian_current_5_9_percent));
      rows[11].push(formatPercent(data.american_indian_future_5_9_percent));
      rows[12].push(formatPercent(data.american_indian_2020_10_14_percent));
      rows[13].push(formatPercent(data.american_indian_current_10_14_percent));
      rows[14].push(formatPercent(data.american_indian_future_10_14_percent));
      rows[15].push(formatPercent(data.american_indian_2020_15_19_percent));
      rows[16].push(formatPercent(data.american_indian_current_15_19_percent));
      rows[17].push(formatPercent(data.american_indian_future_15_19_percent));
      rows[18].push(formatPercent(data.asian_2020_5_9_percent));
      rows[19].push(formatPercent(data.asian_current_5_9_percent));
      rows[20].push(formatPercent(data.asian_future_5_9_percent));
      rows[21].push(formatPercent(data.asian_2020_10_14_percent));
      rows[22].push(formatPercent(data.asian_current_10_14_percent));
      rows[23].push(formatPercent(data.asian_future_10_14_percent));
      rows[24].push(formatPercent(data.asian_2020_15_19_percent));
      rows[25].push(formatPercent(data.asian_current_15_19_percent));
      rows[26].push(formatPercent(data.asian_future_15_19_percent));
      rows[27].push(formatPercent(data.black_2020_5_9_percent));
      rows[28].push(formatPercent(data.black_current_5_9_percent));
      rows[29].push(formatPercent(data.black_future_5_9_percent));
      rows[30].push(formatPercent(data.black_2020_10_14_percent));
      rows[31].push(formatPercent(data.black_current_10_14_percent));
      rows[32].push(formatPercent(data.black_future_10_14_percent));
      rows[33].push(formatPercent(data.black_2020_15_19_percent));
      rows[34].push(formatPercent(data.black_current_15_19_percent));
      rows[35].push(formatPercent(data.black_future_15_19_percent));
      rows[36].push(formatPercent(data.hispanic_2020_5_9_percent));
      rows[37].push(formatPercent(data.hispanic_current_5_9_percent));
      rows[38].push(formatPercent(data.hispanic_future_5_9_percent));
      rows[39].push(formatPercent(data.hispanic_2020_10_14_percent));
      rows[40].push(formatPercent(data.hispanic_current_10_14_percent));
      rows[41].push(formatPercent(data.hispanic_future_10_14_percent));
      rows[42].push(formatPercent(data.hispanic_2020_15_19_percent));
      rows[43].push(formatPercent(data.hispanic_current_15_19_percent));
      rows[44].push(formatPercent(data.hispanic_future_15_19_percent));
      rows[45].push(formatPercent(data.multiple_races_2020_5_9_percent));
      rows[46].push(formatPercent(data.multiple_races_current_5_9_percent));
      rows[47].push(formatPercent(data.multiple_races_future_5_9_percent));
      rows[48].push(formatPercent(data.multiple_races_2020_10_14_percent));
      rows[49].push(formatPercent(data.multiple_races_current_10_14_percent));
      rows[50].push(formatPercent(data.multiple_races_future_10_14_percent));
      rows[51].push(formatPercent(data.multiple_races_2020_15_19_percent));
      rows[52].push(formatPercent(data.multiple_races_curent_15_19_percent));
      rows[53].push(formatPercent(data.multiple_races_future_15_19_percent));
      rows[54].push(formatPercent(data.other_races_2020_5_9_percent));
      rows[55].push(formatPercent(data.other_races_current_5_9_percent));
      rows[56].push(formatPercent(data.other_races_future_5_9_percent));
      rows[57].push(formatPercent(data.other_races_2020_10_14_percent));
      rows[58].push(formatPercent(data.other_races_current_10_14_percent));
      rows[59].push(formatPercent(data.other_races_future_10_14_percent));
      rows[60].push(formatPercent(data.other_races_2020_15_19_percent));
      rows[61].push(formatPercent(data.other_races_current_15_19_percent));
      rows[62].push(formatPercent(data.other_races_future_15_19_percent));
      rows[63].push(formatPercent(data.pacific_islander_2020_5_9_percent));
      rows[64].push(formatPercent(data.pacific_islander_current_5_9_percent));
      rows[65].push(formatPercent(data.pacific_islander_future_5_9_percent));
      rows[66].push(formatPercent(data.pacific_islander_2020_10_14_percent));
      rows[67].push(formatPercent(data.pacific_islander_current_10_14_percent));
      rows[68].push(formatPercent(data.pacific_islander_future_10_14_percent));
      rows[69].push(formatPercent(data.pacific_islander_2020_15_19_percent));
      rows[70].push(formatPercent(data.pacific_islander_current_15_19_percent));
      rows[71].push(formatPercent(data.pacific_islander_future_15_19_percent));
      rows[72].push(formatPercent(data.white_2020_5_9_percent));
      rows[73].push(formatPercent(data.white_current_5_9_percent));
      rows[74].push(formatPercent(data.white_future_5_9_percent));
      rows[75].push(formatPercent(data.white_2020_10_14_percent));
      rows[76].push(formatPercent(data.white_current_10_14_percent));
      rows[77].push(formatPercent(data.white_future_10_14_percent));
      rows[78].push(formatPercent(data.white_2020_15_19_percent));
      rows[79].push(formatPercent(data.white_current_15_19_percent));
      rows[80].push(formatPercent(data.white_future_15_19_percent));
      rows[81].push(formatPercent(data.current_households_under50K_percent));
      rows[82].push(formatPercent(data.future_households_under50K_percent));
    });

    return [headers, ...rows];
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold' }}>
        ESRI Demographic Data by Drive Time
      </Typography>
      <FormControl fullWidth sx={{ mb: 4 }}>
        <InputLabel id="school-select-label">Select a School</InputLabel>
        <Select
          labelId="school-select-label"
          id="school-select"
          value={selectedSchool ? selectedSchool.ncessch : ''}
          label="Select a School"
          onChange={handleSchoolChange}
        >
          {schools.map((school) => (
            <MenuItem key={school.ncessch} value={school.ncessch}>
              {formatSchoolName(school.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedSchool && esriData ? (
        <Paper elevation={3} sx={{ p: 3 }}>
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h5" component="div">
              {selectedSchool.name}
            </Typography>
            <CSVLink
              data={prepareCSVData()}
              filename={`${selectedSchool.name.replace(/\s+/g, '_')}_drive_time_data.csv`}
              className="btn btn-primary"
            >
              <Button variant="contained" color="primary">
                Download CSV
              </Button>
            </CSVLink>
          </Box>
          <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
            <Table size="small" sx={{ '& th, & td': { fontSize: '0.875rem' } }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: 'primary.main' }}>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Metric</TableCell>
                  <TableCell align="right" sx={{ color: 'white', fontWeight: 'bold' }}>5 min</TableCell>
                  <TableCell align="right" sx={{ color: 'white', fontWeight: 'bold' }}>10 min</TableCell>
                  <TableCell align="right" sx={{ color: 'white', fontWeight: 'bold' }}>15 min</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prepareCSVData().slice(1).map((row, index) => (
                  <TableRow key={index} sx={{ '&:nth-of-type(odd)': { backgroundColor: 'action.hover' } }}>
                    <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>{row[0]}</TableCell>
                    <TableCell align="right">{row[1]}</TableCell>
                    <TableCell align="right">{row[2]}</TableCell>
                    <TableCell align="right">{row[3]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Typography variant="h6" align="center" sx={{ mt: 4 }}>
        </Typography>
      )}
    </Container>
  );
}

export default DriveTimePage;