import React, { useState, useEffect, useMemo } from 'react';
import { 
  Container, 
  Grid, 
  Typography, 
  Box, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
  Paper,
  CircularProgress,
  Button,
  Alert,
  Chip
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { SchoolOutlined, LocationOnOutlined, GradeOutlined } from '@mui/icons-material';
import SchoolEnrollmentProjectionChart from './SchoolEnrollmentProjectionChart';
import { formatSchoolName } from '../utils/nameFormatter';

const API_URL = process.env.REACT_APP_BACKEND_URL;

function SchoolDashboard() {
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [enrollmentData, setEnrollmentData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState('total');
  const [availableGrades, setAvailableGrades] = useState(['total']);

  useEffect(() => {
    const fetchSchools = async () => {
      setIsLoading(true);
      setError(null);
      try {
        console.log('Fetching schools from:', `${API_URL}/schools`);
        console.log('Access token:', localStorage.getItem('access_token'));

        const response = await fetch(`${API_URL}/schools`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
          }
        });
        
        console.log('Response status:', response.status);
        console.log('Response headers:', Object.fromEntries(response.headers.entries()));

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const text = await response.text();
        console.log('Response text:', text);

        let data;
        try {
          data = JSON.parse(text);
        } catch (e) {
          console.error('Error parsing JSON:', e);
          throw new Error('Failed to parse response as JSON');
        }

        console.log('Parsed data:', data);

        if (!Array.isArray(data)) {
          throw new Error('Unexpected data format: not an array');
        }

        setSchools(data);
      } catch (error) {
        console.error('Error fetching schools:', error);
        setError(`Failed to fetch schools: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSchools();
  }, []);

  const sortedSchools = useMemo(() => {
    return [...schools].sort((a, b) => 
      formatSchoolName(a.name).localeCompare(formatSchoolName(b.name))
    );
  }, [schools]);

  useEffect(() => {
    const fetchProjections = async () => {
      if (!selectedSchool) return;

      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(`${API_URL}/school/${selectedSchool.ncessch}/enrollment-projections`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
          }
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setEnrollmentData(data);
        
        // Extract available grades
        const grades = new Set();
        Object.values(data.actual_enrollment || {}).forEach(yearData => {
          Object.keys(yearData || {}).forEach(grade => {
            grades.add(grade);
          });
        });
        const sortedGrades = ['total', ...Array.from(grades).sort()];
        setAvailableGrades(sortedGrades);
      } catch (error) {
        console.error('Error fetching projections:', error);
        setError(`Failed to fetch enrollment projections: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProjections();
  }, [selectedSchool]);

  const handleSchoolChange = (event) => {
    const schoolId = event.target.value;
    const school = schools.find(s => s.ncessch === schoolId);
    if (school) {
      setSelectedSchool(school);
      setEnrollmentData(null);
    }
  };

  const handleGradeChange = (event) => {
    setSelectedGrade(event.target.value);
  };

  const formatAddress = (school) => {
    return `${school.street_address}, ${school.city}, ${school.state} ${school.zip_code}`;
  };

  if (isLoading && !selectedSchool) {
    return (
      <Container maxWidth="lg">
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Alert severity="error">
          {error}
          <br />
          Please check the console for more details and try refreshing the page.
          If the problem persists, contact support.
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>Historical & Projected Enrollment</Typography>
      <FormControl fullWidth sx={{ mb: 4 }}>
        <InputLabel id="school-select-label">Select a School</InputLabel>
        <Select
          labelId="school-select-label"
          id="school-select"
          value={selectedSchool ? selectedSchool.ncessch : ''}
          label="Select a School"
          onChange={handleSchoolChange}
        >
          {sortedSchools.map((school) => (
            <MenuItem key={school.ncessch} value={school.ncessch}>
              {formatSchoolName(school.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedSchool && (
        <Box sx={{ mb: 4, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          <Chip icon={<SchoolOutlined />} label={formatSchoolName(selectedSchool.name)} color="primary" />
          <Chip icon={<LocationOnOutlined />} label={formatAddress(selectedSchool)} />
          <Chip icon={<GradeOutlined />} label={selectedSchool.grade_range || 'Not available'} />
        </Box>
      )}
      {selectedSchool && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6">Enrollment Trends and Projections</Typography>
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel id="grade-select-label">Grade</InputLabel>
                  <Select
                    labelId="grade-select-label"
                    id="grade-select"
                    value={selectedGrade}
                    label="Grade"
                    onChange={handleGradeChange}
                  >
                    {availableGrades.map((grade) => (
                      <MenuItem key={grade} value={grade}>
                        {grade === 'total' ? 'Total' : grade}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {isLoading ? (
                <CircularProgress />
              ) : error ? (
                <Typography color="error">{error}</Typography>
              ) : enrollmentData ? (
                <>
                  <SchoolEnrollmentProjectionChart 
                    school={selectedSchool} 
                    enrollmentData={enrollmentData}
                    selectedGrade={selectedGrade}
                  />
                  {!enrollmentData.based_on_user_data && (
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                      <Button
                        color="primary"
                        component={RouterLink}
                        to={`/data-entry`}
                        startIcon={<AddIcon />}
                        variant="contained"
                      >
                        Add Your Enrollment Data
                      </Button>
                    </Box>
                  )}
                </>
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                  <Typography>No projections available</Typography>
                  <Button
                    color="primary"
                    component={RouterLink}
                    to={`/data-entry`}
                    startIcon={<AddIcon />}
                    variant="contained"
                  >
                    Add Enrollment Data
                  </Button>
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

export default SchoolDashboard;