import React, { useState, useEffect, useCallback, useMemo, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Container, 
  Grid, 
  Typography, 
  Box, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
  Card,
  CardContent,
  ToggleButton,
  ToggleButtonGroup,
  Alert,
  Tooltip,
  Chip,
  Skeleton
} from '@mui/material';
import { 
  PeopleOutlined,
  TrendingUpOutlined,
  HomeOutlined,
  MapOutlined,
  SchoolOutlined,
  LocationOnOutlined,
  GradeOutlined,
} from '@mui/icons-material';
import { formatSchoolName, capitalizeWord } from '../utils/nameFormatter';
import { point } from '@turf/helpers';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';

// Lazy load components
const SchoolMap = React.lazy(() => import('./SchoolMap'));
const CompetitorSchoolsComparison = React.lazy(() => import('./CompetitorSchoolsComparison'));
const CurrentEsriMarketShareCard = React.lazy(() => import('./CurrentEsriMarketShareCard'));
const EnrollmentMarketShareCard = React.lazy(() => import('./EnrollmentMarketShareCard'));
const FutureMarketShareCard = React.lazy(() => import('./FutureMarketShareCard'));


const API_URL = process.env.REACT_APP_BACKEND_URL;

function MarketShareDashboard() {
  const [schools, setSchools] = useState([]);
  const [allSchools, setAllSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [esriData, setEsriData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [esriLoading, setEsriLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [selectedDriveTime, setSelectedDriveTime] = useState(10);
  const [enrollmentData, setEnrollmentData] = useState({});
  const [schoolsEnrollmentData, setSchoolsEnrollmentData] = useState([]);


  const formatNumber = (num) => {
    return num ? num.toLocaleString() : 'N/A';
  };

  const formatPercent = (num) => {
    return num !== null && num !== undefined ? `${(num * 100).toFixed(0)}%` : 'N/A';
  };

  const calculatePopulationData = useCallback((driveTimeData) => {
    const currentTotal = driveTimeData['5_9_current_pop'] + driveTimeData['10_14_current_pop'] + driveTimeData['15_19_current_pop'];
    const futureTotal = driveTimeData['5_9_future_pop'] + driveTimeData['10_14_future_pop'] + driveTimeData['15_19_future_pop'];
    const percentChange = ((futureTotal - currentTotal) / currentTotal);

    return {
      currentTotal,
      futureTotal,
      percentChange,
      currentBreakdown: {
        '5-9': driveTimeData['5_9_current_pop'],
        '10-14': driveTimeData['10_14_current_pop'],
        '15-19': driveTimeData['15_19_current_pop']
      },
      futureBreakdown: {
        '5-9': driveTimeData['5_9_future_pop'],
        '10-14': driveTimeData['10_14_future_pop'],
        '15-19': driveTimeData['15_19_future_pop']
      },
      percentChangeBreakdown: {
        '5-9': ((driveTimeData['5_9_future_pop'] - driveTimeData['5_9_current_pop']) / driveTimeData['5_9_current_pop']),
        '10-14': ((driveTimeData['10_14_future_pop'] - driveTimeData['10_14_current_pop']) / driveTimeData['10_14_current_pop']),
        '15-19': ((driveTimeData['15_19_future_pop'] - driveTimeData['15_19_current_pop']) / driveTimeData['15_19_current_pop'])
      }
    };
  }, []);

  const fetchSchools = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        setError('No access token found. Please log in again.');
        navigate('/login');
        return;
      }

      const response = await fetch(`${API_URL}/schools`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem('access_token');
          navigate('/login');
          return;
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const uniqueSchools = Array.from(new Map(data.map(school => [school.ncessch, school])).values())
        .sort((a, b) => formatSchoolName(a.name).localeCompare(formatSchoolName(b.name)));

      setSchools(uniqueSchools);
    } catch (error) {
      console.error('Error fetching school data:', error);
      setError('Failed to load school data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  const fetchAllSchools = useCallback(async () => {
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch(`${API_URL}/all-schools`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setAllSchools(data);
    } catch (error) {
      console.error('Error fetching all schools:', error);
      setError('Failed to load all schools data. Please try again later.');
    }
  }, []);

  const fetchMarketShareData = useCallback(async (schoolId) => {
    setEsriLoading(true);
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch(`${API_URL}/school/${schoolId}/esri-market-share`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setEsriData(data);
    } catch (error) {
      console.error('Error fetching ESRI market share data:', error);
      setError('Failed to load ESRI market share data. Please try again later.');
    } finally {
      setEsriLoading(false);
    }
  }, []);

  const filteredSchools = useMemo(() => {
    if (!selectedSchool || !esriData || !allSchools) {
      return [];
    }

    if (!selectedSchool.grades || selectedSchool.grades.length === 0) {
      return [];
    }

    const selectedGrades = selectedSchool.grades.map(grade => grade.replace('Grade ', '').trim());

    return allSchools.filter(school => {
      if (!school.grades_offered) {
        return false;
      }
      const schoolGrades = school.grades_offered.split(',').map(grade => grade.replace('G_', '').replace('_OFFERED', '').trim());
      const hasOverlap = selectedGrades.some(grade => schoolGrades.includes(grade));
      
      if (!hasOverlap) {
        return false;
      }

      const schoolPoint = point([school.longitude, school.latitude]);
      let driveTime = null;

      for (const time of [5, 10, 15]) {
        if (esriData.drive_times && 
            esriData.drive_times[time] && 
            esriData.drive_times[time].drive_time_polygon) {
          const polygonData = esriData.drive_times[time].drive_time_polygon;
          let polygon;

          if (typeof polygonData === 'string') {
            try {
              const parsedPolygon = JSON.parse(polygonData);
              polygon = {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "Polygon",
                  coordinates: parsedPolygon.rings || parsedPolygon
                }
              };
            } catch (error) {
              console.error(`Error parsing polygon data for ${time} minutes:`, error);
              continue;
            }
          } else if (typeof polygonData === 'object' && polygonData.rings) {
            polygon = {
              type: "Feature",
              properties: {},
              geometry: {
                type: "Polygon",
                coordinates: polygonData.rings
              }
            };
          } else {
            console.warn(`Unexpected polygon data format for ${time} minutes:`, polygonData);
            continue;
          }

          if (booleanPointInPolygon(schoolPoint, polygon)) {
            driveTime = time;
            break;
          }
        }
      }

      return driveTime !== null;
    }).map(school => {
      let driveTime = null;
      for (const time of [5, 10, 15]) {
        if (esriData.drive_times && 
            esriData.drive_times[time] && 
            esriData.drive_times[time].drive_time_polygon) {
          const polygonData = esriData.drive_times[time].drive_time_polygon;
          let polygon;

          if (typeof polygonData === 'string') {
            try {
              const parsedPolygon = JSON.parse(polygonData);
              polygon = {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "Polygon",
                  coordinates: parsedPolygon.rings || parsedPolygon
                }
              };
            } catch (error) {
              console.error(`Error parsing polygon data for ${time} minutes:`, error);
              continue;
            }
          } else if (typeof polygonData === 'object' && polygonData.rings) {
            polygon = {
              type: "Feature",
              properties: {},
              geometry: {
                type: "Polygon",
                coordinates: polygonData.rings
              }
            };
          } else {
            console.warn(`Unexpected polygon data format for ${time} minutes:`, polygonData);
            continue;
          }

          const schoolPoint = point([school.longitude, school.latitude]);
          if (booleanPointInPolygon(schoolPoint, polygon)) {
            driveTime = time;
            break;
          }
        }
      }
      return {
        ...school,
        drive_time: driveTime 
      };
    });
  }, [selectedSchool, allSchools, esriData]);

  const fetchCompetitorSchoolsData = useCallback(async () => {
    if (!selectedSchool || !esriData || filteredSchools.length === 0) {
      return;
    }

    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch(`${API_URL}/competitor-schools-enrollment`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ncessch_list: filteredSchools.map(school => school.ncessch)
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch competitor schools data');
      }

      const data = await response.json();
      
      // Create an object with ncessch as keys and the school data as values
      const enrollmentObject = data.reduce((acc, school) => {
        acc[school.ncessch] = school;
        return acc;
      }, {});
      setEnrollmentData(enrollmentObject);
    } catch (error) {
      console.error('Error fetching competitor schools data:', error);
      setError('Failed to load competitor schools data. Please try again later.');
    }
  }, [selectedSchool, esriData, filteredSchools]);

  useEffect(() => {
    fetchSchools();
    fetchAllSchools();
  }, [fetchSchools, fetchAllSchools]);

  useEffect(() => {
    if (selectedSchool) {
      fetchMarketShareData(selectedSchool.ncessch);
    }
  }, [selectedSchool, fetchMarketShareData]);

  useEffect(() => {
    if (filteredSchools.length > 0) {
      fetchCompetitorSchoolsData();
    }
  }, [filteredSchools, fetchCompetitorSchoolsData]);

  useEffect(() => {
    const fetchEnrollmentData = async () => {
      if (!selectedSchool) return;

      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${API_URL}/competitor-schools-enrollment`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ncessch_list: [selectedSchool.ncessch, ...filteredSchools.map(school => school.ncessch)]
          })
        });

        if (!response.ok) {
          throw new Error('Failed to fetch enrollment data');
        }

        const data = await response.json();
        const enrollmentObject = data.reduce((acc, school) => {
          acc[school.ncessch] = school;
          return acc;
        }, {});
        setEnrollmentData(enrollmentObject);
      } catch (error) {
        console.error('Error fetching enrollment data:', error);
      }
    };

    fetchEnrollmentData();
  }, [selectedSchool, filteredSchools]);

  useEffect(() => {
    const fetchSchoolsEnrollmentData = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${API_URL}/schools-enrollment-by-grade`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch schools enrollment data');
        }

        const data = await response.json();
        setSchoolsEnrollmentData(data);
      } catch (error) {
        console.error('Error fetching schools enrollment data:', error);
      }
    };

    fetchSchoolsEnrollmentData();
  }, []);

  const handleSchoolChange = (event) => {
    const schoolId = event.target.value;
    const school = schools.find(s => s.ncessch === schoolId);
    if (school) {
      setSelectedSchool({...school, name: formatSchoolName(school.name)});
      setEsriData(null);
    }
  };

  const handleDriveTimeToggle = (event, newDriveTime) => {
    if (newDriveTime !== null) {
      setSelectedDriveTime(newDriveTime);
    }
  };

  const formatAddress = (school) => {
    const formattedStreetAddress = school.street_address
      .split(' ')
      .map(capitalizeWord)
      .join(' ');
  
    const formattedCity = school.city
      .split(' ')
      .map(capitalizeWord)
      .join(' ');
  
    return `${formattedStreetAddress}\n${formattedCity}, ${school.state} ${school.zip_code}`;
  };

  // Render loading skeleton
  const renderSkeleton = () => (
    <Grid container spacing={3} sx={{ mt: 2, mb: 4 }}>
      {[1, 2, 3].map((item) => (
        <Grid item xs={12} md={4} key={item}>
          <Skeleton variant="rectangular" height={200} />
        </Grid>
      ))}
    </Grid>
  );

  if (loading) {
    return (
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold' }}>
          Market Share Dashboard
        </Typography>
        <Skeleton variant="rectangular" height={56} sx={{ mb: 4 }} />
        {renderSkeleton()}
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold' }}>
        Market Share Dashboard
      </Typography>
      <FormControl fullWidth sx={{ mb: 4 }}>
        <InputLabel id="school-select-label">Select a School</InputLabel>
        <Select
          labelId="school-select-label"
          id="school-select"
          value={selectedSchool ? selectedSchool.ncessch : ''}
          label="Select a School"
          onChange={handleSchoolChange}
        >
          {schools.map((school) => (
            <MenuItem key={school.ncessch} value={school.ncessch}>
              {formatSchoolName(school.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedSchool && (
        <Box sx={{ mb: 4, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          <Chip icon={<SchoolOutlined />} label={formatSchoolName(selectedSchool.name)} color="primary" />
          <Chip icon={<LocationOnOutlined />} label={formatAddress(selectedSchool)} />
          <Chip icon={<GradeOutlined />} label={`${selectedSchool.grade_range || 'Not available'}`} />
        </Box>
      )}
      {esriLoading ? (
        renderSkeleton()
      ) : selectedSchool && esriData ? (
        <>
          <Box sx={{ mt: 3, mb: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Drive Time Selection</Typography>
            <ToggleButtonGroup
              value={selectedDriveTime}
              exclusive
              onChange={handleDriveTimeToggle}
              aria-label="drive times"
              color="primary"
            >
              {[5, 10, 15].map((time) => (
                <ToggleButton key={time} value={time} aria-label={`${time} minutes`}>
                  {time} min
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>
          <Grid container spacing={3} sx={{ mt: 2, mb: 4 }}>
            <Grid item xs={12} md={4}>
              <Tooltip title={
                <React.Fragment>
                  <Typography color="inherit">Population Breakdown:</Typography>
                  <Typography>Ages 5-9: {formatNumber(calculatePopulationData(esriData.drive_times[selectedDriveTime]).currentBreakdown['5-9'])}</Typography>
                  <Typography>Ages 10-14: {formatNumber(calculatePopulationData(esriData.drive_times[selectedDriveTime]).currentBreakdown['10-14'])}</Typography>
                  <Typography>Ages 15-19: {formatNumber(calculatePopulationData(esriData.drive_times[selectedDriveTime]).currentBreakdown['15-19'])}</Typography>
                </React.Fragment>
              } arrow>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <PeopleOutlined sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
                      <Typography variant="h6" component="div">
                        K-12 Population
                      </Typography>
                    </Box>
                    <Typography variant="h4" component="div">
                      {formatNumber(calculatePopulationData(esriData.drive_times[selectedDriveTime]).currentTotal)}
                    </Typography>
                    <Typography variant="subtitle1" component="div">
                      Persons aged 5-19
                    </Typography>
                  </CardContent>
                </Card>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={4}>
              <Tooltip title={
                <React.Fragment>
                  <Typography color="inherit">Population Change by Age Group:</Typography>
                  <Typography>Ages 5-9: {formatPercent(calculatePopulationData(esriData.drive_times[selectedDriveTime]).percentChangeBreakdown['5-9'])}</Typography>
                  <Typography>Ages 10-14: {formatPercent(calculatePopulationData(esriData.drive_times[selectedDriveTime]).percentChangeBreakdown['10-14'])}</Typography>
                  <Typography>Ages 15-19: {formatPercent(calculatePopulationData(esriData.drive_times[selectedDriveTime]).percentChangeBreakdown['15-19'])}</Typography>
                </React.Fragment>
              } arrow>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <TrendingUpOutlined sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
                      <Typography variant="h6" component="div">
                        K-12 Population Trends
                      </Typography>
                    </Box>
                    <Typography variant="h4" component="div">
                      {formatPercent(calculatePopulationData(esriData.drive_times[selectedDriveTime]).percentChange)}
                    </Typography>
                    <Typography variant="subtitle1" component="div">
                      From 2024 to 2029
                    </Typography>
                  </CardContent>
                </Card>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={4}>
              <Tooltip title={
                <React.Fragment>
                  <Typography color="inherit">Future % of Households Under $50k:</Typography>
                  <Typography>{formatPercent(esriData.drive_times[selectedDriveTime].under_50k_fy)}</Typography>
                  <Typography color="inherit">Percent Change:</Typography>
                  <Typography>{formatPercent(((esriData.drive_times[selectedDriveTime].under_50k_fy - esriData.drive_times[selectedDriveTime].under_50k_cy) / esriData.drive_times[selectedDriveTime].under_50k_cy))}</Typography>
                </React.Fragment>
              } arrow>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <HomeOutlined sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
                      <Typography variant="h6" component="div">
                        Household Income
                      </Typography>
                    </Box>
                    <Typography variant="h4" component="div">
                      {formatPercent(esriData.drive_times[selectedDriveTime].under_50k_cy)}
                    </Typography>
                    <Typography variant="subtitle1" component="div">
                      Households Under $50k
                    </Typography>
                  </CardContent>
                </Card>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Suspense fallback={<Skeleton variant="rectangular" height={500} />}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <MapOutlined sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
                      <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                        School Location and Service Area
                      </Typography>
                    </Box>
                    <Box sx={{ height: 500, position: 'relative' }}>
                      <SchoolMap 
                        selectedSchool={selectedSchool}
                        filteredSchools={filteredSchools}
                        esriData={esriData}
                        visibleDriveTimes={[selectedDriveTime]}
                        enrollmentData={enrollmentData}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Suspense>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={3} direction="column">
                <Grid item>
                  <Suspense fallback={<Skeleton variant="rectangular" height={200} />}>
                    <CurrentEsriMarketShareCard
                      selectedSchool={selectedSchool}
                      allSchools={allSchools}
                      esriData={esriData}
                      selectedDriveTime={selectedDriveTime}
                      enrollmentData={enrollmentData}
                    />
                  </Suspense>
                </Grid>
                <Grid item>
                  <Suspense fallback={<Skeleton variant="rectangular" height={200} />}>
                    <EnrollmentMarketShareCard
                      selectedSchool={selectedSchool}
                      filteredSchools={filteredSchools}
                      schoolsEnrollmentData={schoolsEnrollmentData}
                      visibleDriveTimes={[selectedDriveTime]}
                    />
                  </Suspense>
                </Grid>
                <Grid item>
                  <Suspense fallback={<Skeleton variant="rectangular" height={200} />}>
                    <FutureMarketShareCard
                      selectedSchool={selectedSchool}
                      allSchools={allSchools}
                      selectedDriveTime={selectedDriveTime}
                    />
                  </Suspense>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Suspense fallback={<Skeleton variant="rectangular" height={300} />}>
            <CompetitorSchoolsComparison
              filteredSchools={filteredSchools}
              selectedSchool={selectedSchool}
              visibleDriveTimes={[selectedDriveTime]}
            />
          </Suspense>
        </>
      ) : null}
    </Container>
  );
}

export default MarketShareDashboard;