import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  useTheme,
  IconButton,
  Tooltip,
} from '@mui/material';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import DescriptionOutlined from '@mui/icons-material/DescriptionOutlined';

const menuItems = [
  { text: 'Network Map', icon: <MapOutlinedIcon />, path: '/map' },
  { text: 'Projected Enrollment', icon: <AutoGraphOutlinedIcon />, path: '/school-dashboard' },
  { text: 'Market Share', icon: <DonutSmallOutlinedIcon />, path: '/market-share' },
  { text: 'Reports', icon: <DescriptionOutlined />, path: '/data-reports' },
];

const Sidebar = ({ onToggle }) => {
  const location = useLocation();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(true);

  const handleDrawerToggle = () => {
    setIsOpen(!isOpen);
    onToggle(!isOpen);
  };

  const drawerWidth = isOpen ? 240 : 70;

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: theme.palette.grey[200],
          color: theme.palette.text.primary,
          borderRight: 'none',
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: isOpen ? 200 : 80, 
          mb: 2,
          pt: isOpen ? 4 : 0, // Add top padding when sidebar is open
        }}>
          <Box
            component={RouterLink}
            to="/"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '&:hover': { opacity: 0.8 },
            }}
          >
            <img 
              src={isOpen ? "/images/edc_logo.png" : "/images/edc_logo_circle.png"} 
              alt="EDC Logo" 
              style={{ 
                height: isOpen ? 175 : 50, 
                maxWidth: '100%', 
                objectFit: 'contain'
              }} 
            />
          </Box>
        </Box>
        <List sx={{ flexGrow: 1, mt: 2, mb: 2 }}>
          {menuItems.map((item) => (
            <Tooltip key={item.text} title={!isOpen ? item.text : ""} placement="right">
              <ListItem
                button
                component={RouterLink}
                to={item.path}
                selected={location.pathname === item.path}
                sx={{
                  py: 1.5,
                  minHeight: 48,
                  justifyContent: isOpen ? 'initial' : 'center',
                  '&.Mui-selected': {
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isOpen ? 3 : 'auto',
                    justifyContent: 'center',
                    color: location.pathname === item.path ? theme.palette.primary.main : theme.palette.text.secondary,
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                {isOpen && (
                  <ListItemText 
                    primary={item.text} 
                    primaryTypographyProps={{
                      fontSize: '0.9rem',
                      fontWeight: location.pathname === item.path ? 'bold' : 'normal',
                      color: location.pathname === item.path ? theme.palette.primary.main : theme.palette.text.primary,
                    }}
                  />
                )}
              </ListItem>
            </Tooltip>
          ))}
        </List>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <Tooltip title={isOpen ? "Close sidebar" : "Open sidebar"} placement="right">
            <IconButton onClick={handleDrawerToggle}>
              {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar;